<template>
  <a :href="'/news/' + news.id">
    <div class="each-news" v-show="is_loaded">
      <div class="news-image-container">
        <img :src="news.image" alt="News画像" @load="is_loaded = true" />
      </div>
      <div class="tag-and-posted-date">
        <span> {{ news.tag }}&nbsp;|&nbsp;{{ news.date }} </span>
      </div>
      <div class="title">
        {{ news.title }}
      </div>
    </div>
    <img
      src="/assets/images/loading.gif"
      v-show="!is_loaded"
      class="loading-img"
    />
  </a>
</template>
<script>
export default {
  props: {
    news: Object,
  },
  data() {
    return {
      is_loaded: false,
    };
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
.each-news {
  width: 313px;
  @include flex-column(center, center);
  .news-image-container {
    @include flex-column(center, center);
    width: 100%;
    img {
      width: 100%;
      height: 228px;
      object-fit: cover;
      border-radius: 5px;
    }
    .loading-img {
      width: 50px;
      height: 50px;
    }
  }
  .tag-and-posted-date {
    width: 95%;
    margin: 20px 0 10px 0;
    color: #8b8b8b;
    font-size: 12px;
    @include flex-row(flex-start, center);
    span {
      display: inline-block;
      line-height: 14px;
      vertical-align: baseline;
    }
  }
  .title {
    width: 95%;
    font-size: 18px;
    @include flex-row(flex-start, center);
    color: #222222;
  }
}

@media screen and (max-width: 1024px) {
  .each-news {
    width: 100%;
    .news-image-container {
      @include flex-column(center, center);
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
        border-radius: 5px;
      }
      .loading-img {
        width: 50px;
        height: 50px;
      }
    }
    .tag-and-posted-date {
      margin: 20px 0 10px 0;
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      @include flex-row(center, center);
      span {
        display: inline-block;
        line-height: 14px;
        vertical-align: baseline;
      }
    }
    .title {
      font-size: 18px;
      @include flex-row(center, center);
      color: black;
    }
  }
}

@media screen and (max-width: 599px) {
  .each-news {
    width: 90vw;
    @include flex-column(center, center);
    .news-image-container {
      @include flex-column(center, center);
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
        border-radius: 5px;
      }
      .loading-img {
        width: 50px;
        height: 50px;
      }
    }
    .tag-and-posted-date {
      width: 80vw;
      margin: 20px 0 10px 0;
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      @include flex-row(center, center);
      span {
        display: inline-block;
        line-height: 14px;
        vertical-align: baseline;
      }
    }
    .title {
      width: 80vw;
      font-size: 18px;
      @include flex-row(center, center);
      color: black;
    }
  }
}
</style>
